<template>
  <div class="about">
    <container-with-title
      class="zxkw"
      content-title="关于我们"
      operate-text=""
      :img-src="require('../../../assets/images/home/about-us.png')"
    >
      <div class="zxkw-wrap">
        <div class="zxkw-content" :class="{ open: isOpen, android: isAndroid }">
          <span class="btn" @click="isOpen = !isOpen">
            <span>{{ isOpen ? "收起" : "展开" }}</span>
            <i class="iconfont icon-icon_xia"></i>
          </span>

          <p>
            <span>中新宽维传媒科技有限公司</span>
            是中国新闻出版传媒集团旗下企业，2021年注册于中国（上海）自由贸易区临港新片区，公司通过运用大数据、人工智能、区块链等技术，建设国家级文化大数据平台，服务国家海外传播和文化走出去战略，开展国际传播和文化出海，专注于数字贸易创新发展，与全球头部直播平台合作，建设高质量的跨境直播生态系统，服务全国跨境贸易企业，提供一站式直播出海网络通道及大数据服务。
          </p>
          <p>
            2021年3月，中新宽维与中国（上海）自由贸易区临港新片区管委会签署深度合作协议，在跨境数据区域内建设“中宽维数字港”，打造国际传播与文化出海领域的跨境服务平台。
          </p>
          <p>
            2021年12月，中新宽维与中国（上海）自由贸易区临港新片区管委会签署战略合作框架协议，在临港新片区开展国际传播、文化出海、数字贸易、大数据研究等合作内容。
          </p>
          <p>
            2022年7月，中新宽维与临港科技城与上海临港新片区信息飞鱼经济发展有限公司、上海临港新片区跨境数据科技有限公司签署战略合作框架协议，三方在跨境数据流通、跨境直播贸易生态链等领域开展深入合作。
          </p>
          <p>
            2022年10月，临港新片区首款服务跨境直播的网络服务产品“e路播”正式上线，为出海企业、跨境直播间提供一站式网络通道和大数据服务，跨境出海不再“灰色”。
          </p>
        </div>
      </div>
    </container-with-title>
    <container-with-title
      content-title="深度合作"
      description="中新宽维与临港新片区共建国际数据港跨境直播基地，共同在临港新片区建设符合国际标准的数字基础设施，汇聚全球数字技术的产业布局，建设友好、开放、稳定的数字贸易规则体系。同步引入头部跨境直播网络平台，吸引优质的跨境直播间入驻，共同打造临港新片区国际数据港数字贸易新业态，服务中国出海企业、MCN机构、跨境直播间开辟广阔的海外市场和新增长点。"
      operate-text=""
      :img-src="require('../../assets/images/about/about-2.png')"
    />
    <div class="nums">
      <div class="item">
        <p>推动<span>10个</span>标志性企业率先</p>
        <p>实现跨境数据流通试点</p>
      </div>
      <div class="item">
        <p>汇聚<span>100家</span>数据及智</p>
        <p>能领域的头部企业</p>
      </div>
      <div class="item">
        <p>带动引领<span>100个</span>跨境数</p>
        <p>据交互的示范应用项目实施</p>
      </div>
      <div class="item">
        <p>数字产业规模突破</p>
        <p><span>1000亿</span></p>
      </div>
    </div>

    <container-with-title
      :img-src="require('@/assets/images/about/zxkw.png')"
      content-title="中新宽维数字港"
      operate-text=""
    />
  </div>
</template>

<script>
import ContainerWithTitle from "../../components/ContainerWithTitle";
import { isIOS } from "../../../utils/utils";

export default {
  components: { ContainerWithTitle },
  data() {
    return {
      isOpen: false,
      isAndroid: !isIOS(),
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/scss/helper.scss";

.about {
  background: #d3e0ff;
  padding: 50px 0;

  .zxkw {
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;

    .zxkw-wrap {
      display: flex;
      overflow: hidden;

      .zxkw-content {
        font-size: 61px;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: justify;
        position: relative;
        line-height: 1.5;
        max-height: 4.5em;

        &.android {
          &:before {
            height: calc(100% - 1.5em);
          }
        }

        &.open {
          max-height: none;
          overflow: visible;
          padding-bottom: 1.5em;

          &::before {
            content: "";
            height: calc(100% - 1.4em);
            height: 100%;
          }

          .btn {
            &:before {
              content: "";
            }
            .icon-icon_xia {
              transform: rotate(180deg);
            }
          }
        }

        &::before {
          content: "";
          height: calc(100% - 1.25em);
          float: right;
        }

        &::after {
          content: "";
          width: 999vw;
          height: 999vw;
          position: absolute;
          box-shadow: inset calc(100px - 999vw) calc(30px - 999vw) 0 0 #fff;
          margin-left: -100px;
          margin-top: 1.5em;
        }

        .btn {
          position: relative;
          float: right;
          clear: both;
          margin-left: 50px;
          font-size: 61px;
          line-height: 1.5em;
          height: 1.4em;
          border-radius: 4px;
          color: #4370f7;
          cursor: pointer;

          &::before {
            content: "...";
            font-size: 61px;
            position: absolute;
            left: -5px;
            color: #333;
            transform: translateX(-100%);
          }

          .icon-icon_xia {
            transition: 0.3s;
            font-size: 61px;
            display: inline-block;
          }
        }

        p {
          color: #39425c;
          margin: 0;
          text-indent: 2em;

          span {
            color: #ff9c00;
          }
        }
      }
    }
  }
  .nums {
    margin: 34px 0;
    padding: 0 68px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 34px;
    row-gap: 34px;
    .item {
      height: 393px;
      background: rgba(255, 255, 255, 0.5);
      border: 2px solid #ffffff;
      border-radius: 51px;
      @include flex(column, center, center);
      p {
        margin: 0;
        text-align: center;
        color: #242b55;
        font-size: 61px;
        line-height: 88px;
        span {
          color: #fc8e00;
          font-size: 109px;
        }
      }
    }
  }
}
</style>
